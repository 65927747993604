import i18next from "i18next";

export function customTranslationFormatters() {
  i18next.services.formatter?.add("lowercase", (value) => {
    if (value === null || value === undefined) {
      return value;
    }
    return value.toLowerCase();
  });
  i18next.services.formatter?.add("capitalize", (value) => {
    if (value === null || value === undefined) {
      return value;
    }
    return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
  });
}
